const production = true;
export const environment = {
  version: "0.40",
  beta: false,
  videosPath: "https://videos.voulez-vous.ge",
  basePathNoPort: production
    ? "https://api.voulez-vous.ge"
    : "http://localhost:8050",
  basePath: production ? "https://api.voulez-vous.ge" : "https://api.voulez-vous.ge:8020",
  imagePath: production
    ? "https://api.voulez-vous.ge"
    : "https://api.voulez-vous.ge",
  remoteAddr: "http://194.233.168.68",
  apiKey: "app_key",
  apiSecret: "app_secret",
  GooleMapsKey: "AIzaSyBqwF8rkrutCvJBq90YW7w1idb7uYMnQuM",
  imagesPath: "static/images",
  allowedDocumentFormats: [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "image/png",
    "image/jpeg",
  ],
  eGiftCardId: "636fb2fbef78a0fcdb803766",
  giftCardId: "636fb2fbef78a0fcdb803767",
  facebookAPPID: "244256288160116",
  giftCardOptions: [20, 50, 80, 100, 200, 500, 1000, 1500],
  company: {
    phoneNumber: "237 22 72",
  },
  params: [
    "age_range",
    "benefits",
    "brand",
    "category",
    "cellulite_and_strech_marks",
    "color_family",
    "concerns",
    "coverage",
    "finish",
    "formulation",
    "color",
    "ingredient_preferences",
    "foundation",
    "fragrance_family",
    "fragrance_type",
    "p_category",
    "size",
    "tag",
    "skin_type",
    "sun_protection",
    "price"
  ],
  filterType: "accordion",
};
